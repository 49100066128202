@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .animation {
    @apply transition duration-300 ease-in-out;
  }

  .link {
    @apply transition duration-300 ease-in-out hover:text-purple-900 font-extrabold;
  }

  .h2 {
    @apply text-3xl font-extrabold my-10;
  }
}
